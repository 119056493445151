import Cookies from 'js-cookie';
import React from 'react';
import { AuthProvider, AuthProviderProps, useAuth } from 'react-oidc-context';
import { BrowserRouter, useLocation } from 'react-router-dom';

import { captureException } from '@sentry/react';

import BrandSelector from './components/DevTools/BrandSelector';
import { ErrorBoundry } from './components/ErrorBoundry';
import Layout from './components/Layout';
import Button from './components/Library/Button';
import SalesDemo from './components/SalesDemo';
import ToastNotifications from './components/ToastNotifications';
import { AccountContextProvider } from './contexts/AccountContext';
import { UserContextProvider } from './contexts/UserContext';
import { UserPreferenceContextProvider } from './contexts/UserPreferenceContext';
import { localObjectStorage } from './libs/ObjectStorage';
import { RETURN_URL } from './pages/Callback';
import Error from './pages/Error';
import AuthError from './pages/Error/Auth';
import Loading from './pages/Loading';
import Routes from './pages/PageRoutes';
import Auth0User from './types/Auth0User';

// type AuthUser = AuthZeroUser & Auth0User;

const RouteErrorBoundryApp: React.FC = () => {
  const location = useLocation();

  return <ErrorBoundry key={location.pathname}>
    <ErrorBoundry.OK>
      <Routes />
    </ErrorBoundry.OK>
    <ErrorBoundry.Error component={Error} />
  </ErrorBoundry>;
};

const AuthenticatedApp: React.FC = () => {
  return <BrowserRouter>
          <UserContextProvider>
            <AccountContextProvider>
              <UserPreferenceContextProvider>
                <BrandSelector />
                <SalesDemo />
                <Layout>
                  <RouteErrorBoundryApp />
                </Layout>
              </UserPreferenceContextProvider>
            </AccountContextProvider>
          </UserContextProvider>
        </BrowserRouter>;
};

const InnerApp: React.FC = () => {
  const auth = useAuth();
  // const { isAuthenticated, error, isLoading, user, loginWithRedirect, logout } = useAuth0<AuthUser>();

  if (auth.isLoading) return <Loading fullScreen={true} />;

  const userLogout = (): void => {
    void auth.signoutRedirect();
  };

  if (auth.error != null) {
    // if (error.message.includes('/error/')) {
    //   logout({
    //     logoutParams: {
    //       returnTo: `${window.location.protocol}//${window.location.host}${error.message}`
    //     }
    //   });
    //   return <Loading fullScreen={true} />;
    // }
    if (auth.error.message.includes('mismatching_state')) {
      window.location.assign('/');
      return <Loading fullScreen={true} />;
    }

    captureException(auth.error);

    return (
      <AuthError errorText={auth.error.message} errorText2='Please contact your IT Admin to get access.'>
        <Button variant='secondary' className='px-3 py-2 text-lg' onClick={() => { userLogout(); }}>Change Login</Button>
      </AuthError>
    );
  }

  if (!auth.isAuthenticated || auth.user == null || auth.user?.expired === true) {
    const redirectUrl = `${window.location.pathname}${window.location.search}${window.location.hash}`;
    if (!redirectUrl.includes('callback')) {
      console.log('Set Redirect:', redirectUrl);
      localObjectStorage.set(RETURN_URL, redirectUrl);
    }
    void auth.signinRedirect();
    return <Loading fullScreen={true} />;
  }
  console.log(auth.user);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user: Auth0User = ((auth.user.profile as unknown) as Auth0User);
  if (auth.isAuthenticated && !auth.isLoading && user.whitedog_app_metadata.user_info.service_provider.account_id != null) {
    if (location.hostname !== user.whitedog_app_metadata.user_info.portalUrl && user.whitedog_app_metadata.user_info.portalUrl != null) {
      if (import.meta.env.VITE_FORCE_PORTAL_URL === 'true') {
        const currentRedirectCountStr = Cookies.get('wdportalredirectcount') ?? '0';
        const redirectCount = parseInt(currentRedirectCountStr);
        if (redirectCount > 5) {
          window.open(`https://${user.whitedog_app_metadata.user_info.portalUrl}/logout/`);
          window.location.replace(`https://${window.location.hostname}/logout/`);
        } else {
          Cookies.set('wdportalredirectcount', (redirectCount + 1).toString(), { domain: 'whitedog.app' });
          if (window.location.pathname.includes('callback')) {
            window.location.replace(`https://${user.whitedog_app_metadata.user_info.portalUrl}`);
          } else {
            window.location.replace(`https://${user.whitedog_app_metadata.user_info.portalUrl}${window.location.pathname}${window.location.search}${window.location.hash}`);
          }
        }
      } else {
        console.log('Would redirect to: ', `${user.whitedog_app_metadata.user_info.portalUrl}${window.location.pathname}${window.location.search}${window.location.hash}`);
      }
    } else if (user.whitedog_app_metadata.user_info.portalUrl == null) {
      if (import.meta.env.VITE_FORCE_PORTAL_URL === 'true') {
        window.location.replace('https://my.whitedog.app');
      }
    }
  }

  return <AuthenticatedApp />;
};

const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_MA_URL,
  client_id: import.meta.env.VITE_MA_CLIENT_ID,
  redirect_uri: `${window.location.origin}/callback/`,
  extraQueryParams: {
    audience: import.meta.env.VITE_API_AUDIENCE
  },
  scope: 'openid profile email account portal',
  loadUserInfo: true
};

const App: React.FC = () => {
  return <>
    <AuthProvider {...oidcConfig}>
      <InnerApp />
      <ToastNotifications />
    </AuthProvider>
  </>;
};

export default App;
